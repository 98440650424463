import "./App.css";
import moment from "moment";
import React, { useState, useEffect } from "react";
import trackVisitor from "./utilities/tracker";
import DaySattaResult from "./js/daySattaResult";
import AdvertisementComponent from "./utilities/advertismentComponent";
import { Helmet } from "react-helmet";
const momenttz = require("moment-timezone");

function App() {
  const [data, setData] = useState([]);
  const [datagame, setDataFor] = useState([]);

  const currentDate = moment().tz("Asia/Kolkata").format("YYYY-MM-DD");
  const currentTime = moment().format("HH:mm");
  const todayDate = moment(new Date()).format("lll");
  const prevDate = moment()
    .subtract(1, "days")
    .tz("Asia/Kolkata")
    .format("YYYY-MM-DD");

  useEffect(() => {
    trackVisitor();
  }, []);

  useEffect(() => {
    fetch("https://api.sattakingvip.co.in/getData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        game_name: "",
        curr_date: currentDate,
        prev_date: prevDate,
        open_time: "market_sunday_time_open",
      }),
    })
      .then((response) => response.json())
      .then((json) => {
        // Sort data based on open_time
        const sortedData = json.sort((a, b) => {
          const timeA = moment(a.open_time, "HH:mm");
          const timeB = moment(b.open_time, "HH:mm");
          return timeA.diff(timeB);
        });

        // Set sorted data into state
        setData(sortedData);
      })
      .catch((error) => console.error(error));
  }, [currentDate, prevDate]);

  useEffect(() => {
    if (data?.length > 0) {
      // Convert current time to a moment object for comparison
      const currentMoment = moment(currentTime, "HH:mm");

      // Process and filter the data
      const processedData = data.map((item) => {
        const itemTime = moment(item.open_time, "HH:mm");
        const resultAvailable = item?.curr_date?.result ? true : false;

        return {
          gameName: item.game_name,
          result: resultAvailable ? item?.curr_date?.result : "wait",
          openTime: item.open_time,
          isAvailable: resultAvailable,
          itemTime: itemTime,
        };
      });

      // Sort the processed data by open_time
      const sortedProcessedData = processedData.sort((a, b) => {
        return a.itemTime.diff(b.itemTime);
      });

      // Separate records into those with available results and those with "wait"
      const availableResults = sortedProcessedData.filter(
        (item) => item.isAvailable
      );
      const upcomingRecords = sortedProcessedData.filter(
        (item) => !item.isAvailable
      );

      // Determine the records to display
      let recordsToDisplay = [];

      if (availableResults.length > 0) {
        // Show available results and include records up to the next upcoming record
        recordsToDisplay = [...availableResults];

        const lastAvailableIndex = sortedProcessedData.indexOf(
          availableResults[availableResults.length - 1]
        );
        const nextRecord = sortedProcessedData[lastAvailableIndex + 1];
        if (nextRecord) {
          recordsToDisplay.push(nextRecord);
        }
      } else {
        // No available results, show up to 3 upcoming records with "wait"
        recordsToDisplay = [...upcomingRecords.slice(0, 3)];
      }

      // Ensure only 3 records are shown
      if (recordsToDisplay.length > 3) {
        // Remove the oldest record if more than 3 records are present
        recordsToDisplay = recordsToDisplay.slice(-3);
      }

      // Update state with the processed and limited data
      setDataFor(recordsToDisplay);

      // Debugging log
    }
  }, [data, currentTime]);

  return (
    <div className="App">
      {/* seo setup start */}
      <Helmet>
        <title>Satta Sport</title>
        <meta name="description" content="sattasport, satta sport, sattaking" />
        <meta name="Keywords"
          content="sattasport, satta sport, sattakingreal, satta king real, sattaking real, Satta King, Satta King live result, Satta king online result, Satta king online, Satta king result today, Gali result, Desawar result, Faridabad result, Gaziyabad result, Satta matka king, Satta Bazar, Black satta king, Satta king 2017, satta king 2018, Gali Leak Number, Gali Single Jodi, Black Satta Result, Desawar Single Jodi, Satta king up, Satta king desawar, Satta king gali, Satta king 2019 chart, Satta baba king, Satta king chart, Gali live result, Disawar live result, Satta Number, Matka Number, Satta.com, Satta Game, Gali Number, Delhi Satta king," />
        <link rel="canonical" href="https://sattasport.co/" />
      </Helmet>
      {/* seo setup end */}
      <div className="col-12 homeHeader ">
        <div className="row main-row">
          <div className="col-md-4 col-sm-4 text-center text-danger fw-b link">
            <a href="/">HOME</a>
          </div>
          <div className="col-md-4 col-sm-4 text-center text-danger fw-b link">
            <a href="http://s-king.co/">SATTA KING</a>
          </div>
          <div className="col-md-4 col-sm-4 text-center text-danger fw-b link">
            <a href="http://satta-king-black.co.in/">BLACK SATTA</a>
          </div>
        </div>
      </div>
      <marquee className="text-warning">
        {" "}
        satta number, black satta king sss, satta com, gali satta result, satta
        king live result, gali result, satta king live, satta king disawar,
        black satta 786, satta king gali, satta king com, gali satta chart,
        disawar result, satta disawar, satta live result, disawar chart, gali
        chart, satta king bazar, satta king desawar,{" "}
      </marquee>
      <div className="col-12 text-center header-heading">
        <h6>SATTA KING SATTAKING SATTA RESULT SATTA KING UP</h6>
        <h1>SATTA SPORT</h1>
      </div>

      <div className="col-12 text-white text-center p-1">
        <em>
          INDIA NO.1 SATTA KING SITE SATTASPORT HEARTILY WELCOME. HERE YOU MAY
          GET EXCELLENT ESTIMATION BY PRIME INDIVIDUAL AND QUICK MATKA RESULT.
          GALI DISAWAR FIX SINGLE JODI KE LIYE HERE YOU FIND TOP SATTA MARKET OF
          INDIA FARIDABAD GHAZIABAD GALI DESAWAR SATTA TIPS FAST SATTA RESULT
          GALI DISAWAR GHAZIABAD FARIDABAD SATTA GUESSING POWERED BY
          SATTASPORT.CO
        </em>
      </div>

      <div className="text-center currentBanner mt-3">
        <h5>Wellcome To Satta Sport</h5>
        <h6>हा भाई यही आती हे सबसे तेज़ खबर रूको और धेखो</h6>
      </div>
      <div className="col-12 text-center bottomResult">
        <div>
          <h5 className="color">{todayDate} </h5>
          {datagame.map((todayData, index) => (
            <div key={index} className="game">
              <h3 className="mb-0 text-danger">{todayData.gameName}</h3>
              <h3 className="lh-1 blinking-text">{todayData.result || ""}</h3>
            </div>
          ))}
        </div>
        <AdvertisementComponent type='odd' />
        <DaySattaResult dayGameData={data} />
      </div>
    </div>
  );
}

export default App;
